.App {
  padding: 1em;
}

input[type=text] {
  width: 100%;
  margin-top: 0.5em;
}

button {
  margin-right: 1em;
  margin-top: 1em;
}

input[type=submit] {
  margin-right: 1em;
  margin-top: 1em;
}

form {
  display: inline;
}

ul {
  margin: 0;
}